import React, { useState } from "react";

import { css } from '@emotion/react';
import { styled } from '@mui/material/styles';
import Map from "./Map";
import DetailView from "./DetailView";
import { Chip } from "@mui/material";


const plots = [
    {
         "beacons": "52.979,267.219 52.979,313.73 52.979,351.517 61.972,351.517 76.67,329.711 98.223,297.736",
         "x": 65.967,
         "y": 318.5716666666667,
         "status": "Available",
         "plotNo": 100
    },
    {
         "beacons": "52.979,267.219 98.223,297.736 119.019,266.884 61.965,228.4 52.979,241.722",
         "x": 77.033,
         "y": 260.3922,
         "status": "Booked",
         "buyer": {
              "title": "Dr",
              "firstName": "Tony",
              "otherNames": "Muthee",
              "phone": "0720327686"
         },
         "plotNo": 101
    },
    {
         "beacons": "139.702,236.199 82.657,197.723 61.965,228.4 119.019,266.884",
         "x": 100.83574999999999,
         "y": 232.3015,
         "status": "Available",
         "plotNo": 102
    },
    {
         "beacons": "160.193,205.8 103.158,167.329 82.657,197.723 139.702,236.199",
         "x": 121.4275,
         "y": 201.76275000000004,
         "status": "Available",
         "plotNo": 103
    },
    {
         "beacons": "123.61,137.007 103.158,167.329 160.193,205.8 180.636,175.471",
         "x": 141.89925,
         "y": 171.40175,
         "status": "Available",
         "plotNo": 104
    },
    {
         "beacons": "144.017,106.754 123.61,137.007 180.636,175.471 201.032,145.212",
         "x": 162.32375000000002,
         "y": 141.111,
         "status": "Sold",
         "buyer": {
              "title": "Mr",
              "firstName": "Patrick",
              "otherNames": "Mwangi Mwas",
              "phone": "0795676565"
         },
         "plotNo": 105
    },
    {
         "beacons": "222.096,113.961 165.09,75.511 144.017,106.754 201.032,145.212",
         "x": 183.05875,
         "y": 110.3595,
         "status": "Available",
         "plotNo": 106
    },
    {
         "beacons": "243.693,81.921 186.698,43.477 165.09,75.511 222.096,113.961",
         "x": 204.39425,
         "y": 78.7175,
         "status": "Available",
         "plotNo": 107
    },
    {
         "beacons": "245.059,38.473 190.073,38.473 186.698,43.477 243.693,81.921 264.252,51.42 272.979,38.473",
         "x": 233.79233333333335,
         "y": 48.70616666666667,
         "status": "Available",
         "plotNo": 108
    },
    {
         "beacons": "345.381,38.473 299.979,38.473 280.64,38.473 293.935,47.441 350.993,85.927 371.228,55.907 382.979,38.473",
         "x": 332.16214285714284,
         "y": 49.023857142857146,
         "status": "Available",
         "plotNo": 109
    },
    {
         "beacons": "319.313,132.927 339.872,102.426 282.814,63.939 262.254,94.44",
         "x": 301.06325000000004,
         "y": 98.433,
         "status": "Available",
         "plotNo": 110
    },
    {
         "beacons": "297.716,164.968 319.313,132.927 262.254,94.44 240.658,126.481",
         "x": 279.98525,
         "y": 129.704,
         "status": "Sold",
         "buyer": {
              "title": "Mrs",
              "firstName": "Mary",
              "otherNames": "Mwikali",
              "phone": "0720987676"
         },
         "plotNo": 111
    },
    {
         "beacons": "240.658,126.481 219.593,157.731 276.651,196.218 297.716,164.968",
         "x": 258.6545,
         "y": 161.34949999999998,
         "status": "Available",
         "plotNo": 112
    },
    {
         "beacons": "276.651,196.218 219.593,157.731 199.197,187.991 256.255,226.478",
         "x": 237.924,
         "y": 192.10449999999997,
         "status": "Available",
         "plotNo": 113
    },
    {
         "beacons": "256.255,226.478 199.197,187.991 178.754,218.319 235.812,256.806",
         "x": 217.5045,
         "y": 222.3985,
         "status": "Available",
         "plotNo": 114
    },
    {
         "beacons": "178.754,218.319 158.263,248.719 215.321,287.205 235.812,256.806",
         "x": 197.0375,
         "y": 252.76225,
         "status": "Available",
         "plotNo": 115
    },
    {
         "beacons": "137.58,279.404 194.638,317.891 215.321,287.205 158.263,248.719",
         "x": 176.4505,
         "y": 283.30475,
         "status": "Available",
         "plotNo": 116
    },
    {
         "beacons": "137.58,279.404 116.784,310.256 173.842,348.742 194.638,317.891",
         "x": 155.711,
         "y": 314.07325000000003,
         "status": "Available",
         "plotNo": 117
    },
    {
         "beacons": "116.784,310.256 95.231,342.23 88.972,351.517 109,351.517 171.972,351.517 173.842,348.742",
         "x": 125.96683333333333,
         "y": 342.62983333333335,
         "status": "Sold",
         "buyer": {
              "title": "Dr",
              "firstName": "Tony",
              "otherNames": "Muthee",
              "phone": "0720327686"
         },
         "plotNo": 118
    },
    {
         "beacons": "215.321,287.205 194.638,317.891 173.842,348.742 171.972,351.517 177.956,351.517 244.491,351.517 263.668,351.517 278.357,329.725",
         "x": 215.030625,
         "y": 336.203875,
         "status": "Sold",
         "buyer": {
              "title": "Dr",
              "firstName": "Tony",
              "otherNames": "Muthee",
              "phone": "0720327686"
         },
         "plotNo": 119
    },
    {
         "beacons": "235.812,256.806 215.321,287.205 278.357,329.725 298.849,299.324",
         "x": 257.08475,
         "y": 293.265,
         "status": "Available",
         "plotNo": 120
    },
    {
         "beacons": "235.812,256.806 298.849,299.324 319.292,268.996 256.255,226.478",
         "x": 277.552,
         "y": 262.901,
         "status": "Available",
         "plotNo": 121
    },
    {
         "beacons": "256.255,226.478 319.292,268.996 339.688,238.736 276.651,196.218",
         "x": 297.9715,
         "y": 232.607,
         "status": "Available",
         "plotNo": 122
    },
    {
         "beacons": "276.651,196.218 339.688,238.736 360.752,207.486 297.716,164.968",
         "x": 318.70174999999995,
         "y": 201.85199999999998,
         "status": "Available",
         "plotNo": 123
    },
    {
         "beacons": "297.716,164.968 360.752,207.486 382.349,175.445 319.313,132.927",
         "x": 340.0325,
         "y": 170.20649999999998,
         "status": "Available",
         "plotNo": 124
    },
    {
         "beacons": "319.313,132.927 382.349,175.445 402.909,144.944 339.872,102.426",
         "x": 361.11075,
         "y": 138.9355,
         "status": "Sold",
         "buyer": {
              "title": "Mr",
              "firstName": "Allan",
              "otherNames": "Mwakazi",
              "phone": "0720976345"
         },
         "plotNo": 125
    },
    {
         "beacons": "434.264,98.426 371.228,55.907 350.993,85.927 414.03,128.446",
         "x": 392.62874999999997,
         "y": 92.1765,
         "status": "Available",
         "plotNo": 126
    },
    {
         "beacons": "412.692,38.473 382.979,38.473 371.228,55.907 434.264,98.426 455.302,67.214 474.675,38.473",
         "x": 421.85666666666674,
         "y": 56.161,
         "status": "Available",
         "plotNo": 127
    },
    {
         "beacons": "547.571,38.473 501.675,38.473 495.461,47.693 473.864,79.734 535.493,121.303 557.09,89.262 568.632,72.138 568.632,52.68 568.632,38.473",
         "x": 535.2277777777778,
         "y": 64.24766666666666,
         "status": "Available",
         "plotNo": 128
    },
    {
         "beacons": "452.826,110.945 514.455,152.515 535.493,121.303 473.864,79.734",
         "x": 494.15950000000004,
         "y": 116.12424999999999,
         "status": "Available",
         "plotNo": 129
    },
    {
         "beacons": "452.826,110.945 432.59,140.965 494.219,182.534 514.455,152.515",
         "x": 473.52250000000004,
         "y": 146.73975,
         "status": "Available",
         "plotNo": 130
    },
    {
         "beacons": "421.469,157.464 400.911,187.965 462.54,229.534 463.536,230.206 481.959,200.722 483.04,198.994",
         "x": 452.2425,
         "y": 200.81416666666667,
         "status": "Available",
         "plotNo": 131
    },
    {
         "beacons": "462.54,229.534 400.911,187.965 379.313,220.006 440.942,261.575 443.047,262.995 463.536,230.206",
         "x": 431.7148333333334,
         "y": 232.0468333333333,
         "status": "Available",
         "plotNo": 132
    },
    {
         "beacons": "440.942,261.575 379.313,220.006 358.25,251.256 419.877,292.825 423.065,294.975 443.047,262.995",
         "x": 410.749,
         "y": 263.9386666666667,
         "status": "Available",
         "plotNo": 133
    },
    {
         "beacons": "419.877,292.825 358.25,251.256 337.853,281.516 399.481,323.084 403.715,325.94 423.065,294.975",
         "x": 390.3735,
         "y": 294.93266666666665,
         "status": "Available",
         "plotNo": 134
    },
    {
         "beacons": "399.481,323.084 337.853,281.516 317.41,311.844 376.227,351.517 380.317,351.517 387.733,351.517 403.715,325.94",
         "x": 371.8194285714286,
         "y": 328.13357142857143,
         "status": "Available",
         "plotNo": 135
    },
    {
         "beacons": "317.41,311.844 296.918,342.244 290.668,351.517 310.666,351.517 376.227,351.517",
         "x": 318.37780000000004,
         "y": 341.7278,
         "status": "Available",
         "plotNo": 136
    },
    {
         "beacons": "432.59,140.965 452.826,110.945 473.864,79.734 495.461,47.693 501.675,38.473 481.792,38.473 474.675,38.473 455.302,67.214 434.264,98.426 414.03,128.446 350.993,85.927 293.935,47.441 280.64,38.473 272.979,38.473 264.252,51.42 243.693,81.921 222.096,113.961 201.032,145.212 180.636,175.471 160.193,205.8 139.702,236.199 119.019,266.884 98.223,297.736 76.67,329.711 61.972,351.517 88.972,351.517 95.231,342.23 116.784,310.256 137.58,279.404 158.263,248.719 178.754,218.319 199.197,187.991 219.593,157.731 240.658,126.481 262.254,94.44 282.814,63.939 339.872,102.426 402.909,144.944 382.349,175.445 360.752,207.486 339.688,238.736 319.292,268.996 298.849,299.324 278.357,329.725 263.668,351.517 290.668,351.517 296.918,342.244 317.41,311.844 337.853,281.516 358.25,251.256 379.313,220.006 400.911,187.965 421.469,157.464 483.04,198.994 483.098,199.033 494.219,182.534",
         "x": 300.20533928571433,
         "y": 186.4104821428571,
         "status": "Road",
         "plotNo": 137
    }
]



const StyledRoot = styled("div")(
    ({ theme }) => 
    css`
        width: 100vw;
		height: 100vh;

        background-color: ${theme.palette.divider};
        

        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: 1fr;

        gap: 1px;

        align-items: stretch;
        justify-items: stretch;

        
        > .status {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
            align-self: flex-end;
            justify-self: flex-end;


            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 1px;
            padding: 1px;
            background-color: ${theme.palette.divider};

            margin: ${theme.spacing(1)};
            

            > div {
                background-color: ${theme.palette.background.default};
                text-align: center;
                padding: ${theme.spacing(0.5)} ${theme.spacing(1)};
                font: ${theme.typography.subtitle1};

                :nth-of-type(-n+4) {
                    font-weight: 500;
                }
            }

        }


    `
);



const Root = () => {

    const [selected, setSelected] = useState()

    

    return (
        <StyledRoot>
            
            <Map className='map' plots={plots} selected={selected} setSelected={setSelected}/> 
            <DetailView selected={selected}/>    
            <div className="status">
                    <div>Total Plots</div>                    
                    <div>Available</div>                    
                    <div>Booked</div>
                    <div>Sold</div>
                    <div>{plots.length}</div>
                    <div>{plots.filter(pl=>pl.status==='Available').length}</div>
                    <div>{plots.filter(pl=>pl.status==='Booked').length}</div>
                    <div>{plots.filter(pl=>pl.status==='Sold').length}</div>

            </div>     
        </StyledRoot>
    )
    
   
};

export default Root;


