import React, { Fragment, useEffect, useRef } from "react";

import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';





const StyledSvg = styled("svg")(
    ({ theme }) => 
    css`
        grid-column: 1 / 2;
        grid-row: 1 / 2;

        min-width: 0;
        min-height: 0;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        background-color: ${theme.palette.background.default};
        
    `
);

const getFill = (theme, status) => {
    if(status === 'Available'){
        return 'none'
    }else if(status === 'Booked'){
        return  'orange'
    }else if(status === 'Sold'){
        return 'green'
    }else if(status === 'Road'){
        return theme.palette.primary.light  
    }
}

const StyledPolygon = styled(
    "polygon", 
    {shouldForwardProp: (props) => props !== 'status' && props !== 'isOverlay'}
)(
    ({ theme, status, isOverlay}) => 
    css`
        pointer-events: visible;
        stroke-miterlimit: 10;
        stroke: #000000;
       

        ${
            status==='Road'?
            css`
                fill: ${theme.palette.primary.light};
            `
            :css`
                fill: ${getFill(theme,status)};         
                :hover {
                    fill: ${theme.palette.action.focus};
                }            
            `           
        }
        
        
        ${
            isOverlay? css`
                fill: ${getFill(theme,status)};            
                stroke: ${theme.palette.secondary.main};
                stroke-width: 2;
            ` :``           
        }
    `
);

const StyledText = styled(
    "text", 
    {shouldForwardProp: (props) =>  props !== 'status' && props !== 'isOverlay'}
)(
    ({ theme, status, isOverlay}) => 
    css`
        fill: ${theme.palette.text.secondary};
        font: ${theme.typography.caption};
        font-size: 8px;       

        ${
            status==='Road'?
            css`
                fill: none;
            `
            :``          
        }
          
    `
);



const PlotPolygon = (props) => {

    const {plot, setSelected, isOverlay} = props
 
    const {beacons, x, y, plotNo, status, buyer} = plot

    const ref = useRef(null);

    const handleClick = event => {
        if(!isOverlay)
            setSelected(plot)
    };

    useEffect(() => {
        const element = ref.current;

        element.addEventListener('click', handleClick);

        return () => {
            element.removeEventListener('click', handleClick);
        };
    }, []);
    

    return (
        <>
            <StyledPolygon  ref={ref} points={beacons}  status={status} isOverlay={isOverlay} /> 
            <StyledText x={x-10} y={y+5} status={status}>{plotNo}</StyledText>
        </>
    )
}
    


const Map = (props) => {

    const {plots, selected, setSelected}  = props

    return (
        <StyledSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 609.724 396.477" >
            {
                plots.map(({beacons, x, y, plotNo, status, buyer})=>(
                    <Fragment key={plotNo}>
                        <PlotPolygon 
                            plot={{beacons, x, y, plotNo, status, buyer}} 
                            setSelected={setSelected}
                        />
                        
                    </Fragment>
                ))
            }
            {
                Boolean(selected) && 
                <>
                    <PlotPolygon
                        plot={{...selected}}
                        isOverlay
                    />
                </>
            }
        </StyledSvg>
    )
    
    
};

export default Map;


