import React, { Fragment, useEffect, useRef, useState } from "react";

import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import { Avatar, Button, ButtonGroup, Divider, Typography } from "@mui/material";


const StyledRoot = styled("div")(
    ({ theme }) => 
    css`
        background-color: ${theme.palette.background.default};
        display: grid;
        grid-template-rows: auto 1fr;
        
        > div:nth-of-type(1) {
            font: ${theme.typography.h5};
            background-color: ${theme.palette.primary.main};
            color: ${theme.palette.getContrastText(theme.palette.primary.main)};
            padding: ${theme.spacing(1)} ${theme.spacing(2)};
        }


        > div:nth-of-type(2) {
            padding: ${theme.spacing(1)} ${theme.spacing(2)};

            display: grid;
            grid-template-rows: 1fr auto auto;
            align-items: stretch;
            gap: ${theme.spacing(1)};

            > div:nth-of-type(1) {
                background-color: ${theme.palette.background.default};
                border-color: ${theme.palette.divider};
                border-radius: ${theme.spacing(1)};
                border-style: solid;
                border-width: 1px;
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: 1fr;
                align-items: center;
                justify-items: center;
                min-height: 0;

                > svg {
                    max-height: 50vh;
                    max-width: 80%;
                }
            } 

            > div:nth-of-type(2) {
                border-color: ${theme.palette.divider};
                border-radius: ${theme.spacing(1)};
                border-style: solid;
                border-width: 1px;
                padding: ${theme.spacing(1)} ${theme.spacing(2)};
                display: grid;
                grid-template-columns: auto 1fr;
                gap: ${theme.spacing(1)};

                .MuiButtonGroup-root{
                    grid-column: 1 / 3;
                    justify-self: flex-end;
                }

            } 

            > div:nth-of-type(3) {
                border-color: ${theme.palette.divider};
                border-radius: ${theme.spacing(1)};
                border-style: solid;
                border-width: 1px;
                padding: ${theme.spacing(1)} ${theme.spacing(2)};
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;

                >.MuiTypography-root{
                    padding: 0 ${theme.spacing(2)};
                }

                >.MuiTypography-root:nth-of-type(1) {
                    grid-column: 1 / 3;
                }

                >.MuiDivider-root:nth-of-type(1) {
                    grid-column: 1 / 3;
                }

                >.MuiAvatar-root:nth-of-type(1) {
                    grid-row: 3 / 5;
                }
            } 
        }

    `
);


const StyledPolygon = styled(
    "polygon", 
    {shouldForwardProp: (props) => props !== 'status'}
)(
    ({ theme, status }) => 
    css`
        stroke: #000000;
        stroke-miterlimit: 10;
        fill: ${getFill(theme,status)}; 
        
    `
);

const getFill = (theme, status) => {
    if(status === 'Available'){
        return 'transparent'
    }else if(status === 'Booked'){
        return  'orange'
    }else if(status === 'Sold'){
        return 'green'
    }
}


const StyledSvg = styled("svg")(
    ({ theme }) => 
    css`
        min-width: 0;
        min-height: 0;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */

        background-color: ${theme.palette.background.default};
        

        > text {
            fill: ${theme.palette.text.secondary};
            font: ${theme.typography.caption};
            font-size: 8px;
        }

        > text:nth-of-type(38) {
            display: none;
        }
    `
);


const DetailView = (props) => {

    const {selected}  = props

    const [viewBox, setViewBox] = useState('0 0 20 20')
    const [points, setPoints] = useState('')
    const [textX, setTextX] = useState(0)
    const [textY, setTextY] = useState(0)

    useEffect(()=>{
        if(selected){
            const beacons = selected.beacons
            const arr = beacons.split(' ')
            const arrX = arr.map(item=>Number(item.split(',')[0]))
            const arrY = arr.map(item=>Number(item.split(',')[1]))


            const minX = Math.min(...arrX)
            const maxX = Math.max(...arrX)
            const minY = Math.min(...arrY)
            const maxY = Math.max(...arrY)


            const offsetPoints = arr.map((item, i)=>{
                return `${arrX[i]-minX+5},${arrY[i]-minY+5}`
            }).join(' ')

            setViewBox(`0 0 ${maxX-minX+10} ${maxY-minY+10}`)
            setPoints(offsetPoints)
            setTextX((maxX-minX)/2)
            setTextY((maxY-minY)/2)

        }

    }, [selected])

    return (
        <StyledRoot >
            <div>Selected Plot</div>
            {
                Boolean(selected) &&
                <div>
                    
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox={viewBox}>
                            <StyledPolygon status={selected.status} points={points}/>
                            <text x={textX-10} y={textY+10}>{selected.plotNo}</text>
                        </svg>
                    </div>
                    <div>
                        <Typography>Plot Number</Typography>
                        <Typography>{selected.plotNo}</Typography>
                        <Typography>Plot Status</Typography>
                        <Typography>{selected.status}</Typography>
                        {
                            selected.status === 'Available' &&
                            <ButtonGroup variant="outlined">
                                <Button>Book</Button>
                                <Button>Sell</Button>
                            </ButtonGroup>
                        }
                    </div>
                    <div>
                        {
                            Boolean(selected.buyer) && 
                            <>
                                <Typography>Buyer Details</Typography> 
                                <Divider orientation="horizontal"/>
                                <Avatar></Avatar>
                                <Typography>{`${selected.buyer.title} ${selected.buyer.firstName} ${selected.buyer.otherNames}`}</Typography>
                                <Typography>{`Phone : ${selected.buyer.phone}`}</Typography>                                
                            </>
                        }
                    </div>
                </div>
            }
            
            
        </StyledRoot>
    )
    
    
};

export default DetailView;


